export default {
  methods: {
    rupiahFormat(value, fraction = 0) {
      return Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: fraction,
      }).format(value);
    }
  }
}