<template>
  <div class="mb-4">
    <h5 class="text-h5 font-weight-bold">{{ title }}</h5>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      default: "Page Title",
    },
  },
};
</script>
